@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@300;400&display=swap');

body {
    margin: 0;
    font-family: 'Mohave', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1F1F1F;
    overflow: hidden;
    user-select: none;
    scrollbar-width: thin;
    scrollbar-color: #55B6CB transparent;
}

#Background {
    width: 100vw;
    height: 100vh;
    background-color: #5EC2D8;
    background-size: cover;
    background-repeat: no-repeat;
}

.top_card,
.middle_card,
.bottom_card,
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 40em;
    max-width: 90vw;
    height: 27.5em;
    background-color: #F5F5F5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(43, 128, 138, 0.6);
    backdrop-filter: blur(7.5px);
    border-radius: 40px;
}

form {
    height: auto;
    margin: 0 auto;
    border: 0;
}

input,
select {
    border: 1px solid #CDDEFF;
    border-radius: 22.5px;
    height: 2.5em;
    width: 100%;
    padding: 0 1em;
    transform: translate(-1em, 0);
}

select {
    width: 112.5%;
}

select option {
    background-color: #f7f7f9;
}

input:focus {
    outline: none;
    border: 1px solid #b7cbf3;
}

input[type=checkbox] {
    transform: translate(6.5em, -.5em);
    width: 1em;
    height: 1em;
    accent-color: #57BBA1;
    cursor: pointer;
}

input[type=submit] {
    background: #57BBA1;
    border: 0px solid black;
    color: #f7f7f9;
    cursor: pointer;
    transform: translate(0, 0) !important;
}

input[type=search] {
    position: absolute;
    top: .5em;
    left: .5em;
    border-radius: 30px;
    width: 25em;
    max-width: 90%;
    margin: 0 auto !important;
    transform: translate(0, 0);
    background: transparent url("../imgs/icons/lente.svg") no-repeat 95% 50%;
    background-size: 22.5px;
    padding-right: 3.25em;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.pass_eye {
    position: absolute;
    top: .75em;
    right: -.5em;
    color: #000;
    cursor: pointer;
}

.top_card {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.App-logo {
    height: auto;
    width: 20em;
}

.middle_card div {
    position: relative;
    height: 100%;
}

.middle_card label {
    position: absolute;
    top: -.5em;
    left: .75em;
    font-size: 80%;
    color: #f7f7f9;
}

.bottom_card {
    color: #f7f7f9;
    text-decoration: none;
    font-size: 80%;
}

.bottom_card a:visited {
    color: #f7f7f9;
    text-decoration: none;
}

.error {
    color: red;
    font-size: 80%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(1em, 0);
}

#Base {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(87,187,161);
}

#Menu {
    width: 7.75vw;
    max-width: 10em;
    height: auto;
    position: relative;
}

.menuTop {
    width: 90%;
    height: auto;
    margin: 2em 2.5% 1.5em 2.5%;
}

.menuntent {
    margin: 4%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuItem {
    width: 100%;
    aspect-ratio: 5 / 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f7f7f9;
    text-decoration: none;
    border-radius: 15px;
    line-height: 0px;
    color: #5e6065;
    transition: .3s;
    font-size: 1em;
}

.menuItem img {
    width: 1.5em;
    height: 1.5em;
}

.menuItem:hover {
    background: rgba(255, 255, 255, 0.1);
}

.menuItemActive {
    background: rgba(255, 255, 255, 0.1);
    aspect-ratio: 6/5;
    padding-top: .6em;
    color: #FFFFFF;
}

#Content {
    background: #FFFFFF;
    width: calc(100% - 8.25vw);
    min-width: calc(100% - 10.5em);
    height: calc(100% - 1em);
    position: absolute;
    top: .5em;
    right: 0;
    border-radius: 30px;
    margin-right: .5em;
}

#Header {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 2em);
    height: 3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    border-radius: 30px 30px 0 0;
    font-style: normal;
    font-weight: 400;
}

.headerPopup {
    position: relative !important;
}

.headerPopup div {
    cursor: pointer;
}

#Body {
    position: absolute;
    top: 3em;
    right: 0;
    width: calc(100% - 1em);
    height: calc(100% - 4.5em);
    border-radius: 30px;
    padding: .5em;
}

.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-out forwards;
}

.headerSX {
    display: flex;
    align-items: center;
    font-size: 1.75em;
    margin-left: .45em;
}

.headerCX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.headerDX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.headerDX div,
img {
    cursor: pointer;
}

.mini-selettore {
    font-size: 90%;
    font-weight: 400 !important;
    padding: 0 .75em !important;
    height: 1.25em;
    max-width: 6em;
}

.mini-selettore img {
    font-weight: 800;
}

.nowidth {
    width: auto !important;
}

.nowidth img {
    width: .9em;
}

.selettore {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    line-height: 0;
    max-height: 2.5em;
    padding: 0 1.25em;
    margin: .15em .25em;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selettorePurple {
    background: #57BBA1 !important;
    color: #fff;
}

.selettoreBlue {
    background: #55B6CB !important;
    color: #fff;
}

.selettoreViolet {
    background: #F2F5FF !important;
    color: #57BBA1;
    font-weight: 600;
}

.selettoreFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    float: right;
    width: 10em;
}

.selettoreMiddle {
    justify-content: center !important;
    width: 7.5em;
    cursor: initial;
}

.selettoreBorder {
    border: 1px solid #CDDEFF;
    background-color: #fff !important;
    color: #57BBA1;
    font-weight: 600;
}

.selettoreShadow {
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.selettoreRound {
    border-radius: 50%;
    border: 1px solid #CDDEFF;
    background: #fff;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: .15em;
    cursor: pointer;
}

.selettoreHeader {
    line-height: 0 !important;
    font-size: 90%;
}

.disabled {
    opacity: .25;
    cursor: not-allowed;
}

.disabledLight {
    opacity: .7;
    cursor: not-allowed;
}

.visualizzazione {
    background-color: #57BBA1;
    border: 0 solid;
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.sottoSelettore {
    width: calc(100% - 2em);
    height: auto;
    max-height: 15em;
    overflow: auto;
    position: absolute;
    top: 2.95em;
    left: 0;
    z-index: 9 !important;
    padding: .5em 1em;
    background: #FFFFFF;
    border: 1px solid #CDDEFF;
    box-shadow: 0px 8px 10px rgba(43, 49, 138, 0.2);
    border-radius: 15px;
    transition: .2s;
    opacity: 0;
    display: none;
}

.flag {
    width: 2.5em;
    filter: grayscale(.2)
}

.sottoSelettore>.row {
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5em;
    color: #A8B1CE;
    transition: .2s;
}

.sottoSelettore>.row img {
    transition: .2s;
}

.sottoSelettore>.row:hover {
    color: #57BBA1;
    font-weight: 600;
}

.sottoSelettore>.row:hover img {
    padding-left: .5em;
}

.noCursor {
    cursor: inherit !important;
}

.bell {
    border-radius: 20px;
    padding: .25em;
    transition: .4s;
}

.bell:hover {
    background: rgba(250, 0, 0, 0.1)
}

#ContentPage {
    width: 100%;
    height: calc(100% - 1.8em);
    display: flexbox;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: 2.4em;
    overflow: auto;
    border-radius: 0 0 20px 20px;
    color: #000;
}

#Griglia,
#GrigliaAlarm {
    /* width: 12.25em;
    height: 12em; RIATTIVARE PER TEMPERATURA STANZA*/
    padding: .5em;
    border-radius: 20px;
    float: left;
    margin: .25em;
    line-height: 12px;
    font-size: 90%;
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
}

#Griglia:hover,
#GrigliaAlarm:hover {
    border: 1px solid #CDDEFF;
}

#Griglia {
    box-shadow: 0px 5px 8px rgba(43, 49, 138, 0.3);
}

#GrigliaAlarm {
    box-shadow: 0px 8px 15px rgba(43, 49, 138, 0.6);
}

#Griglia table,
#GrigliaAlarm table {
    width: 100%;
    border-collapse: collapse;
}

#Griglia td,
#GrigliaAlarm td {
    padding: 0;
    line-height: 20px;
}

#ListaResidenti {
    width: 100%;
    height: 65%;
    overflow: auto;
}

#ListaAmbienti {
    width: 100%;
    height: 35%;
    overflow: auto;
}

#Lista,
#TitoliLista,
.scheda thead {
    width: calc(100% - .25em);
    font-weight: 300 !important;
    font-size: 18px;
    padding-left: .25em;
    line-height: 15px;
    font-size: 95%;
}

#TitoliLista {
    color: #57BBA1;
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 1;
    font-size: 90%;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #CDDEFF;
    border-radius: 10px;
}

#TitoliLista td {
    vertical-align: middle;
    line-height: 100%;
    padding: .15em;
}

#Lista {
    height: 2.65em;
    border-bottom: 1px solid #F5F5F5;
    transition: .4s;
}

#Lista:hover {
    background: rgba(0, 0, 0, 0.02);
}

#Lista td,
#TitoliLista td {
    text-align: left;
    padding-left: .25em;
    font-weight: 300;
    position: relative;
    z-index: 0 !important;
}

.allarme {
    background: #FBE6E5 !important;
    border-radius: 20px;
    margin: .15em 0;
}

.arrowTitoli {
    position: relative;
    top: .1em;
}

.selected {
    font-weight: 600 !important;
}

.left {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em !important;
}

.right {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.alarm,
.alarmG {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 85%;
    font-weight: 500;
}

.alarm img {
    margin-right: .05em;
    margin-top: .15em;
    height: 90%;
}

.alarmG img {
    margin-right: -.25em;
    margin-top: .25em;
    height: 140%;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    right: 1.5em;
    margin-left: .5em;
}

.topLeft {
    position: absolute !important;
    top: 0;
    left: 0;
}

.medium {
    width: 7.5px !important;
    height: 7.5px !important;
}

.big {
    width: 10px !important;
    height: 10px !important;
}

.red {
    background: #FF5149;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FF5149;
}

.green {
    background: #18FFA9 !important;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #18FFA9 !important;
}

.yellow {
    background: #FFE500;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FFE500;
}

.blue {
    background: #55B6CB;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 5px #55B6CB;
}

.bgRed {
    background: #FBE6E5;
}

.bgYellow {
    background: #FEF9E2;
}

.normPosition {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    float: left;
    margin: .5em;
    transform: translate(0, -40%);
}

#Popup,
#PopupHeader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(43, 49, 138, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

#PopupBody {
    position: relative;
    width: calc(100% - 1em);
    height: auto;
    padding: .5em;
}

.popupCardContainer {
    width: 100% !important;
    height: 100%;
    min-height: 30em;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    position: relative;
}

.popupCardContainer::-webkit-scrollbar {
    display: none;
}

.popupCard {
    width: auto;
    height: 100%;
    min-height: 20em;
    margin: 0 .5em 1em .5em;
    border-radius: 22.5px;
}

.popupCard td>label {
    position: relative;
    top: 1em;
}

.popupNavigator {
    width: 100%;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    margin: 0;
    bottom: 0;
}

.popupButtons {
    position: absolute;
    bottom: .5em;
    right: 1em;
}

.popupLegend {
    position: absolute;
    bottom: .5em;
    left: 1em;
}

.popupNavigator img {
    filter: opacity(0.5);
}

.popupNavigator img:hover {
    filter: opacity(1);
}

.popupNavigatorArrow {
    margin: 0 0 0 1em;
    opacity: .5;
}

.popupNavigatorArrow:hover {
    opacity: 1;
}


#MiniPopup {
    position: absolute;
    top: 5em;
    z-index: 100;
    background: #F2F5FF;
    box-shadow: rgba(90, 129, 250, 0.3) 0px 10px 10px;
    border-radius: 22.5px;
    padding: .5em;
    width: 15em;
    display: flex;
    flex-direction: column;
}

#MiniPopup input {
    width: 87.5%;
    position: relative;
    top: .5em;
    left: 0;
    margin: .75em 0 0 0;
    transform: translate(0)
}

#MiniPopup label {
    color: #57BBA1 !important;
    font-weight: 200;
    font-size: 90%;
    position: relative;
    left: .5em;
}

.minipopTable td {
    padding: 0 !important;
}

.minipopTable .selettore {
    width: 80%;
    margin-top: .5em;
}

.minipopTable label {
    position: relative;
    top: 1em;
}

.delMiniPopup {
    position: absolute;
    top: .5em;
    right: 1em;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    line-height: 0;
}

.delMiniPopup:hover {
    background-color: rgba(250, 0, 0, 0.1)
}


.popupContent {
    max-width: calc(100% - 6em);
    max-height: calc(100% - 2.5em);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 40px #57BBA1;
    margin-left: 5em;
    overflow: auto;
}

.closePopup {
    position: absolute;
    top: 1em;
    right: 1.5em;
    padding: .5em;
    border-radius: 50%;
}

.closePopup:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

#options {
    width: 8em;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: .25em;
    margin: 0 .5em;
    z-index: 1;
    position: absolute;
    top: -1.3em;
    left: 50%;
    transform: translate(-50%, 0);
    transition: .2s ease-in-out;
}

#options:hover {
    background: #F2F5FF;
    top: 0;
}

#handle {
    width: 100%;
    height: 4em;
    border-radius: 20px 20px 0 0;
    background-color: #F2F5FF;
    position: relative;
    top: 0;
    left: 0;
    cursor: grab;
    display: flex;
    align-items: center;
}

#handle:active {
    cursor: grabbing;
}

#PopupFooter {
    width: 100%;
    height: 2.5em;
    margin-top: 2em;
    position: relative;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#ContentStruttura {
    width: 100%;
    height: calc(100% - 2em);
    margin: 0;
    padding: 0;
    position: relative;
}

#Strutture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
}

#Magazzino {
    width: auto;
    height: calc(100% - .25em);
    margin-top: 0.25em;
    padding: 0;
    color: #fff;
    z-index: 2;
    position: absolute;
    top: 0;
    right: .15em;
}

.headerMagazzino {
    background: #55B6CB;
    border-radius: 20px;
    width: 1.75em;
    height: calc(100% - 2em);
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}

.headerMagazzino img {
    display: none;
}

.headerMagazzino p {
    transform: rotate(-90deg);
    margin-bottom: 3em;
    transition: .2s;
}

.contentMagazzino {
    width: 0;
    height: 100%;
    float: right;
    margin-left: .35em;
    overflow: hidden;
    background: rgba(90, 129, 250, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    transition: .2s;
    position: relative;
    right: .25em;
    overflow: auto;
}

.contentMagazzino::-webkit-scrollbar-track {
    margin: 1em;
}

.tabMagazzino {
    width: calc(100%- 2em);
    padding: .5em;
    margin: .5em;
    background-color: rgb(242, 245, 255);
    border-radius: 15px;
    color: #57BBA1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tabMagazzino:hover {
    background-color: rgba(242, 245, 255, 0.9);
}

.tabMagazzino p {
    margin: 0 .5em;

}

.tabStruttura {
    height: auto;
    max-height: 2.6em;
    overflow: hidden;
    float: left;
    transition: .4s;
    position: relative;
    padding: 0;
    margin-top: .25em;
    color: #57BBA1;
}

.piano {
    width: calc(100% - 2.25em);
}

.ala,
.corridoio {
    float: right;
    width: calc(100% - 2em);
}

.stanza {
    float: right;
    width: calc(100% - 2em) !important;
    display: flex;
    align-items: center;
    flex-direction: flex-start;
    margin: .15em 0;
}

.add {
    cursor: pointer;
    border: 0 !important;
    font-weight: 800;
    display: none;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.add img {
    margin: 0 1em;
}

.add2 {
    border: 0 !important;
    font-weight: 800;
    height: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1.1em;
    padding-right: .25em;
    position: relative;
    width: auto;
    min-width: 30em;
}

.add2 img {
    width: 1em;
}

.add2 div {
    font-weight: 200;
}

.headerStruttura {
    width: calc(100% - .15em);
    height: 2.6em;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
}

.mini-tab {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.mini-tab div {
    display: flex;
    align-items: center;
}

.modifica {
    background: rgba(43, 49, 138, 0.4);
    backdrop-filter: blur(4px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2 !important;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 2.5em);
    height: 100%;
    align-items: center;
}

.hov {
    opacity: 0;
}

.hov:hover {
    opacity: 1;
}

.full {
    width: 100% !important;
}

.inputMenuMod {
    width: 12.5em;
    max-width: 12.5em;
    transform: translate(0, 0);
    background: transparent;
    color: #fff;
    border: 0px;
    font-size: 100%;
    font-family: 'Mohave', sans-serif;
    margin-left: -.4em;
}

.inputMenuMod::placeholder {
    color: #fff;
    opacity: 1;
}

.inputMenuMod:focus {
    background: transparent;
    color: #fff;
    border: 0px;
}

.mini-tab-stanza {
    border-right: 1px solid #F5F5F5;
    font-weight: 500;
}

.mini-piano {
    min-width: 22em !important;
    border-radius: 20px 0 0 20px;
}

.mini-ala {
    min-width: 20em !important;
    border-radius: 20px 0 0 20px;
}

.mini-corridoio {
    min-width: 18em !important;
    border-radius: 20px 0 0 20px;
}

.mini-right {
    height: 1.25em;
    width: 1.25em;
    float: right;
    position: relative;
}

.mini-right img {
    width: 1.25em;
}

.scheda {
    height: auto;
    min-height: 250px;
    position: relative;
    transition: .4s;
    width: 100%;
    margin: 1em 0;
}

.scheda td {
    padding: 1.5em;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}

.schedaNoBorder td {
    border: 0px solid transparent;
    padding: 0;
}

.tdPointer td {
    cursor: pointer;
    font-weight: 200;
}

.tdPointer td:hover {
    font-weight: 600;
}

.scheda label {
    position: relative;
    color: #000 !important;
    font-size: 85%;
    font-weight: 200;
}

.noBorder {
    border: 0px solid transparent;
    margin-top: .5em;
}

.noBorder td {
    border: 0px solid transparent;
}

.schedaSotto {
    height: auto;
    min-height: 50px;
    transition: .4s;
    width: 100%;
}

.schedaImg img {
    width: 100%;
    height: 100%;
}

.navigators {
    width: 100%;
    height: 2em;
}

#noData {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#noData p {
    font-size: 1.5em;
    font-weight: 500;
    color: #57BBA1;
    animation: blinker 2.5s linear infinite;
}

.progress-bar-container {
    background-color: rgb(242, 245, 255);
    border-radius: 10px;
    height: 1em;
    width: 100%;
    margin: 2em 0 0 0;
    position: relative;
    overflow: hidden;
}

.progress-bar {
    background-color: #55B6CB;
    border-radius: 10px;
    height: 100%;
    transition: width 1s linear;
    float: left;
}

.progress-bar-n {
    color: #fff;
    position: absolute;
    top: 0;
    right: .5em;
    float: left;
    transform: translate(0, -80%);
    filter: drop-shadow(0px 0px 1px #000);
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(43, 49, 138, 0.1);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../imgs/loading.png");
    background-repeat: no-repeat;
    background-position: center;
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #55B6CB;
    border-radius: 6px;
    border: 3px solid transparent;
}

::-webkit-scrollbar-track {
    margin-top: 3px;
    margin-bottom: 10px;
}